/*
 * @Description: 
 * @Author: 周强
 * @Date: 2021-12-16 15:09:55
 * @LastEditTime: 2022-01-05 10:01:00
 * @LastEditors:  
 */
import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.js' // 用于px -> rem
import { NumberKeyboard, Field, Icon, Form, Picker, Popup, Button, CellGroup, Toast, Overlay, Cascader, Uploader, Search, List, Cell, Row, Col, DropdownMenu, Dialog,DropdownItem, PasswordInput, Checkbox, CheckboxGroup } from 'vant'
import VueRouter from 'vue-router'
import routers from '@/utils/routers'
import VueClipboard from 'vue-clipboard2'
import BaiduMap from 'vue-baidu-map-v3'
import store from './store/index'

import { Select, Option, Table, TableColumn, Card, Row as elRow, Col as elCol, Radio as elRadio, Checkbox as elCheckbox, CheckboxGroup as elCheckboxGroup,
  datePicker, InputNumber, Button as elButton, Divider, Message, Pagination, Progress } from 'element-ui'

Vue.use(Select).use(Option).use(Table).use(TableColumn).use(Card).use(elRow).use(elCol).use(elRadio).use(elCheckbox).use(elCheckboxGroup)
.use(datePicker).use(InputNumber).use(elButton).use(Divider).use(Pagination).use(Progress)

Vue.use(VueRouter)
// 全局属性
// Vue.prototype.$baseH5Url = 'http://m.juruiyun.com/#'

// 测试环境
// Vue.prototype.$baseH5Url = 'http://m.dev.juruiyun.com/#'

// 配置后
Vue.prototype.$baseH5Url = process.env.VUE_APP_H5_URL
Vue.prototype.$message = Message


const router = new VueRouter({
  mode: 'history',
  routes: routers
})
Vue.use(BaiduMap, {
  ak: 'oGYnEyKxK3jkCYQrztGMo9ZskmgeAlae'
})
Vue.use(VueClipboard)
Vue.use(NumberKeyboard)
.use(Field)
.use(Icon)
.use(Form)
.use(Picker)
.use(Popup)
.use(Button)
.use(CellGroup)
.use(Toast)
.use(Overlay).use(Cascader).use(Uploader).use(Search).use(List).use(Cell).use(Row).use(Col).use(DropdownMenu).use(DropdownItem).use(Dialog)
.use(PasswordInput).use(Checkbox).use(CheckboxGroup)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
